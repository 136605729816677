// *************** GLOBALS **************
@import './global.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body, html, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: $black;
  overflow-x: hidden;
  background: $background;
}

*:focus {
  outline: none;
}

h1 {
  margin: 10px 0;
  font-size: 21px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  text-transform: capitalize;

  &::before {
    background: $main;
    content: "\00a0 ";
    margin-right: 5px;
  }
}

.cen-noInfo {
  position: absolute;
  display: flex;
  font-size: 3vh;
  left: 50%;
  top: 40%;
  color: $blackO;
  opacity: 0.7;
  transform: translate(-50%, -50%);
}

.display-center {
  display: flex;
  align-items: center;
}

#root .cen-btn-delete {
  color: $delete;
  border: 1px solid $delete;
}
#root .cen-btn-save, #root .cen-btn-create {
  color: $save;
  border: 1px solid $save;

  .MuiCircularProgress-root {
    color: $save;
  }
}
#root .cen-btn-cancel, #root .cen-btn-add, #root .cen-btn-submit {
  color: $secondary1;
  border: 1px solid $secondary1;
}

#root .cen-btn-submit {
  width: 100%;
  height: 40px;
}

#root .cen-btn-new {
  position: fixed;
  right: 35px;
  bottom: 35px;
  background-color: $main;
  color: white;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 15px;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}


// *************** MATERIAL ***********

.MuiTooltip-tooltip {
  font-size: 1.2em !important;
  text-transform: capitalize;
}

#root .MuiSwitch-colorSecondary.Mui-checked {
  color: $main;
}
#root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: $main-hover;
}
#root .MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: $main-08;
}

#root .MuiCircularProgress-root {
  color: $main;
}

#root .MuiOutlinedInput-notchedOutline {
  border-color:$main;
}

// *************** LOGIN **************

.cen-back {
  height: 100%;
  background: $secondary1;
}

.cen-title {
  position: absolute;
  font-size: 36px;
  padding: 20px;
  color: rgba(255,255,255,0.5);
}

.cen-login {
  width: 375px;
  height: 250px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 0px 18px rgba(0, 0, 0, 0.25);
  background: white;
  border-radius: 10px;

  .cen-login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    form {
      display: grid;
      align-items: center;
      height: 170px;
      width: 250px;
  
      input[type="text"], input[type="password"] {
        width: 100%;
        border: 0;
        padding: 15px 15px 15px 45px;
        box-sizing: border-box;
        font-size: 15px;
      }
  
      input[type="submit"] {
        padding: 15px;
  
        &:disabled {
          color: graytext;
          background-color: buttonface;
        }
      }
  
      .cen-icons {
        position: absolute;
        
        svg {
          width: 20px;
          opacity: 0.5;
          position: relative;
          top: 2px;
          left: 10px;
        }
        }
    }
  }
}
// *************** APP **************
.MuiButtonBase-root.MuiFab-root.a-logOut {
  position: absolute;
  right: 15px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-shadow: none;
  > span {
    height: 0;
  }
}

#root .MuiToolbar-regular {
  max-height: 64px;
}

#root .MuiInput-underline:before {
  border-bottom: 2px solid $hover;
}

#root .MuiInput-underline:hover:not(.Mui-disabled):before, #root .MuiInput-underline:after {
  border-bottom: 2px solid $secondary1;
}

.bim-content {
  width: 100%;
  background: #F2F2F2;
  position: absolute;
  top: 65px;
  padding: 5px 20px 0px 20px;
  transition: all 0.40s linear;
  height: calc(100% - 65px);
  box-sizing: border-box;
}

// *************** TABLE ***************

.cen-header-table {
  max-width: 960px;
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 50px;
  opacity: 0.6;
  font-size: 20px;
  box-sizing: border-box;
  min-height: 48px;
  display: grid;
  align-items: center;
  grid-template-columns: 40% 40% auto;

  span {
    padding-left: 8px;
  }
}

.cen-content-table {
  
  width: calc(100% - 50px);
  max-width: 960px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  .cen-select {
    display: grid;
    align-items: center;
    min-height: 48px;
    width: 100%;
    grid-template-columns: 40% 40% auto;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    > span {
      padding-left: 15px;
    }

    .cen-action-button {
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;  
    }

  }
}

.cen-pagination {
  width: calc(100% - 50px);
    max-width: 960px;
    margin: 0 auto;
    margin-top: -1px;
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 10px 10px;
    margin-bottom: 50px;
    text-align: center;
}

// ************* Table Filter **************

.cen-filter {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 20px 0;

  input {
    font-size: 22px;
    max-width: 350px;
    width: 100%;
    color: black;
  }
}

// ********** Actions Table ****************
.cen-border {
  border-style: solid;
  border-width: 4px;
  content: "";
  position: absolute;
  width: 100%;
  background-color: $main;
  border-color: $main;
}

.cen-action-card {

  max-width: 960px;
  margin: 30px auto;
  padding: 0 0 30px 0;
  position: relative;

  .cen-action-header {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    align-items: center;

    .MuiFormControl-root {
      max-width: 350px;
      width: 100%;
    }

    > div {
      margin-top: 8px;
    }
  
    input {
      font-size: 21px;
    }
  
    .MuiInput-underline:before {
      border-bottom: inherit !important;
    }
  
    .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after {
      border-bottom: 2px solid $secondary1;
    }
  
    .MuiCardActions-root.MuiCardActions-spacing > button {
      width: 100px;
    }
  }

  .cen-action-body {
    margin: 0 35px;

    .cen-action-element, .cen-action-element-grid {
      display: grid;
      grid-template-columns: 40% auto;
      margin: 15px 15px;
      column-gap: 20px;
      align-items: flex-start;

      &.cen-action-switch {
        grid-template-columns: auto 100px;
      }

      div:first-child {
        display: grid;
        row-gap: 10px;

        span:nth-child(2) {
          color: rgba(0, 0, 0, 0.54);
          font-size: 12px;
        }
      }

      div:nth-child(2) {
        margin: 0;

        .MuiInput-underline:before {
          border-bottom: 1px solid $hover;
        }
      
        .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after {
          border-bottom: 2px solid $secondary1;
        }
      }
    }

    .cen-action-element-grid {
      grid-template-columns: none;
      row-gap: 19px;

      textarea {
        font-family: monospace;
      }
    }

    .cen-action-expandable {
      
      .MuiExpansionPanelSummary-root {
        padding: 0 14px;

        .MuiExpansionPanelSummary-content {
          display: grid;
          grid-gap: 8px;

          span:nth-child(2) {
            color: rgba(0, 0, 0, 0.54);
            font-size: 12px;
          }
        }
      }
      .MuiExpansionPanelDetails-root {
        display: grid;
        grid-gap: 10px;

        .cen-expandable-element {
          display: grid;
          align-items: center;

          svg {
            color: rgba(0, 0, 0, 0.54);
          }

          > div {
            display: flex;
            align-items: center;

            .cen-element-output {
              width: 100%;
              display: grid;
              grid-template-columns: 50% 50%;
              grid-gap: 5px;
            }
          }

          input {
            padding-right: 65px;
          }

          .MuiFormControl-marginNormal {
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
          }
          
          .MuiCardActions-root {
            cursor: pointer;
            position: absolute;
            right: 20px;
          }
            
          .MuiInput-underline:before {
            border-bottom: inherit;
          }
        
          .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after {
            border-bottom: 2px solid $secondary1;
          }

          .cen-element-input {
            .MuiInput-root {
              width: calc(100% - 40px);
            }
          }
        }
      }
    }
  }
}


// ************** MEDIA **************

@media (max-width: 750px){
  #root {
    .cen-action-element {
      grid-template-columns: inherit;
      row-gap: 15px;
    }
  
    .cen-action-body {
      margin: 0 10px;
    }
  }
}

@media (max-width: 500px){
  #root {
    .cen-title {
      font-size: 25px;
    }

    .cen-header-table, .cen-content-table {
      width: 100%;
      grid-template-columns: 50% 50%;

      span {
        font-size: 18px;
      }
    }

    .cen-filter input {
      font-size: 16px;
    }

    .cen-action-header {
      display: grid;
      justify-content: inherit;

      > div {
        margin-top: 0;
        justify-content: center;
      }
    }
    .cen-login {
      width: 310px;
    }

  }
}