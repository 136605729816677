$main : #f5deb3;
$main-hover: #f3d08e;
$main-08 : rgba(16,138,223, 0.08);
$secondary1 : #f3d08e;
$secondary2 : '';
$secondaryText : rgba(0, 0, 0, 0.54);
$hover : rgba(0, 0, 0, 0.08);
$black : #333;
$blackO : #8c8c8c;
$background : #F2F2F2;
$delete : #dc004e;
$save : #4caf50;